import { render, staticRenderFns } from "./Content_Analysis.vue?vue&type=template&id=4d57afcc&scoped=true&"
import script from "./Content_Analysis.vue?vue&type=script&lang=js&"
export * from "./Content_Analysis.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d57afcc",
  null
  
)

export default component.exports